<template>
  <div class="px-3">
    <p class="sidebarElementTitle">Inline Image Settings</p>

    <div class="image-upload">
      <div>
        <div class="outer" style="position: relative">
          <figure class="figureClass" ref="figureBlock">
            <img :src="getImage" :key="getImage" class="cursor-pointer" />
          </figure>
        </div>
      </div>
    </div>

    <div class="mt-3 d-flex justify-content-center">
      <ImageFileComp
        :selectedImageRoot="getImage"
        @selectedImageCallback="selectedImage($event)"
      />
    </div>

    <div class="mt-3">
      <p class="sidebarElementTitle">Image Position</p>

      <div class="row">
        <div class="col-6 mb-2">
          <div
            class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
            :class="block.style.position === 'start' ? 'selected ' : null"
            @click="block.style.position = 'start'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_154_2545)">
                <path
                  d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5ZM16.6667 4.16667H3.33335V15.8333H16.6667V4.16667ZM9.16669 5.83333V14.1667H5.00002V5.83333H9.16669Z"
                  fill="#73738D"
                />
              </g>
              <defs>
                <clipPath id="clip0_154_2545">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <span
              class="sidebarElementSubTitle"
              style="font-weight: 500; margin-top: 5px"
              :style="block.style.position === 'start' ? 'color:#4d1b7e' : null"
            >
              Left</span
            >
          </div>
        </div>
        <div class="col-6 mb-2">
          <div
            class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
            :class="block.style.position === 'end' ? 'selected ' : null"
            @click="block.style.position = 'end'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_154_2550)">
                <path
                  d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0892 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.49999C2.27898 17.5 2.06701 17.4122 1.91073 17.2559C1.75445 17.0996 1.66666 16.8877 1.66666 16.6667V3.33333C1.66666 3.11232 1.75445 2.90036 1.91073 2.74408C2.06701 2.5878 2.27898 2.5 2.49999 2.5H17.5ZM16.6667 4.16667H3.33332V15.8333H16.6667V4.16667ZM15 5.83333V14.1667H10.8333V5.83333H15Z"
                  fill="#73738D"
                />
              </g>
              <defs>
                <clipPath id="clip0_154_2550">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span
              class="sidebarElementSubTitle"
              style="font-weight: 500; margin-top: 5px"
              :style="block.style.position === 'end' ? 'color:#4d1b7e' : null"
            >
              Right</span
            >
          </div>
        </div>
        <div class="col-6 mb-2">
          <div
            class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
            :class="block.style.position === 'center' ? 'selected ' : null"
            @click="block.style.position = 'center'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_257_1548)">
                <path
                  d="M17.2559 2.74408C17.4769 2.74408 17.6889 2.83187 17.8452 2.98816C18.0014 3.14444 18.0892 3.3564 18.0892 3.57741V16.9107C18.0892 17.1318 18.0014 17.3437 17.8452 17.5C17.6889 17.6563 17.4769 17.7441 17.2559 17.7441H2.25591C2.0349 17.7441 1.82294 17.6563 1.66666 17.5C1.51038 17.3437 1.42258 17.1318 1.42258 16.9107V3.57741C1.42258 3.3564 1.51038 3.14444 1.66666 2.98816C1.82294 2.83187 2.0349 2.74408 2.25591 2.74408H17.2559ZM16.4226 4.41074H3.08925V16.0774H16.4226V4.41074ZM12 6.07741V14H8V6.07741H12Z"
                  fill="#73738D"
                />
              </g>
              <defs>
                <clipPath id="clip0_257_1548">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span
              class="sidebarElementSubTitle"
              style="font-weight: 500; margin-top: 5px"
              :style="
                block.style.position === 'center' ? 'color:#4d1b7e' : null
              "
            >
              Center</span
            >
          </div>
        </div>
        <div class="col-6 mb-2">
          <div
            class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-3"
            :class="block.style.position === 'fullWidth' ? 'selected ' : null"
            @click="block.style.position = 'fullWidth'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clip-path="url(#clip0_154_2556)">
                <path
                  d="M17.5 2.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 16.8877 1.66669 16.6667V3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 2.5 2.50002 2.5H17.5ZM16.6667 4.16667H3.33335V15.8333H16.6667V4.16667ZM15 5.83333V14.1667H5.00002V5.83333H15Z"
                  fill="#73738D"
                />
              </g>
              <defs>
                <clipPath id="clip0_154_2556">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span
              class="sidebarElementSubTitle"
              style="font-weight: 500; margin-top: 5px"
              :style="
                block.style.position === 'fullWidth' ? 'color:#4d1b7e' : null
              "
            >
              Full-Width</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-group d-flex mt-3">
      <div
        class="w-100 d-flex align-items-center"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        <div class="show-logo custom-control custom-switch cursor-pointer">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="`SeperateImageForMobileBlock-${Date.now()}`"
            v-model="block.useMobileImage"
          />

          <label
            class="custom-control-label"
            :for="`SeperateImageForMobileBlock-${Date.now()}`"
            >Use separate Image for Mobile</label
          >
        </div>
      </div>
    </div>

    <div class="mt-3">
      <p class="sidebarElementTitle">Image Size</p>

      <p class="sidebarElementSubTitle m-0 mb-2">
        {{ isDesktopView ? "Desktop Size" : "Mobile Size" }}
      </p>

      <div
        class="d-flex align-items-center justify-content-between"
        v-if="isDesktopView"
      >
        <div class="d-flex align-items-center">
          <span
            style="
              color: var(--grey, #73738d);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            "
          >
            W:</span
          >

          <input
            type="number"
            min="0"
            max="50"
            v-model="block.style.desktopWidth"
            class="form-control-sm px-2 ml-2 input-border-style"
            style="width: 80px; max-width: 80px"
          />
        </div>
        <div class="d-flex align-items-center">
          <span
            style="
              color: var(--grey, #73738d);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            "
          >
            H:</span
          >

          <input
            type="number"
            v-model="block.style.desktopHeight"
            min="0"
            class="form-control-sm px-2 ml-2 input-border-style"
            style="width: 80px; max-width: 80px"
          />
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between" v-else>
        <div class="d-flex align-items-center">
          <span
            style="
              color: var(--grey, #73738d);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            "
          >
            W:</span
          >

          <input
            type="number"
            min="0"
            max="50"
            v-model="block.style.mobileWidth"
            class="form-control-sm px-2 ml-2 input-border-style"
            style="width: 80px; max-width: 80px"
          />
        </div>
        <div class="d-flex align-items-center">
          <span
            style="
              color: var(--grey, #73738d);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 140%; /* 19.6px */
            "
          >
            H:</span
          >

          <input
            type="number"
            v-model="block.style.mobileHeight"
            min="0"
            class="form-control-sm px-2 ml-2 input-border-style"
            style="width: 80px; max-width: 80px"
          />
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-3">
        <div class="d-flex align-items-center">
          <input
            type="color"
            v-model="block.style.backgroundColor"
            class="colorInput"
          />
          <input
            type="text"
            class="form-control-sm px-2 ml-2 input-border-style"
            style="max-width: 80px"
            v-model="block.style.backgroundColor"
          />
        </div>
        <div class="d-flex align-items-center">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8333 13.8333H15.5V15.5H13.8333V13.8333ZM13.8333 12.1667H15.5V10.5H13.8333V12.1667ZM0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM0.5 2.16667H2.16667V0.5H0.5V2.16667ZM3.83333 2.16667H5.5V0.5H3.83333V2.16667ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5ZM0.5 15.5H2.16667V13.8333H0.5V15.5ZM15.5 4.66667C15.5 2.36667 13.6333 0.5 11.3333 0.5H7.16667V2.16667H11.3333C12.7083 2.16667 13.8333 3.29167 13.8333 4.66667V8.83333H15.5V4.66667Z"
              fill="#B3AFB6"
            />
          </svg>

          <input
            type="number"
            min="0"
            max="50"
            v-model="block.style.borderRadius"
            class="form-control-sm px-2 ml-2 input-border-style"
            style="width: 80px; max-width: 80px"
          />
        </div>
      </div>
    </div>

    <div class="mt-3 row">
      <div class="col-6">
        <div
          class="roundedContainer d-flex align-items-center justify-content-center cursor-pointer py-2"
          @click="block.style.backgroundSize = 'contain'"
          :class="{
            selected: block.style.backgroundSize === 'contain',
          }"
        >
          <span
            class="sidebarElementSubTitle"
            :style="
              block.style.backgroundSize === 'contain'
                ? 'color:#4d1b7e;font-weight:500;'
                : null
            "
            >Fit</span
          >
        </div>
      </div>
      <div class="col-6">
        <div
          class="roundedContainer d-flex align-items-center justify-content-center cursor-pointer py-2"
          @click="block.style.backgroundSize = 'cover'"
          :class="{
            selected: block.style.backgroundSize === 'cover',
          }"
        >
          <span
            class="sidebarElementSubTitle"
            :style="
              block.style.backgroundSize === 'cover'
                ? 'color:#4d1b7e;font-weight:500;'
                : null
            "
            >Fill</span
          >
        </div>
      </div>
    </div>

    <div class="mt-3 pb-5">
      <div class="row m-0" v-if="isDesktopView">
        <h4 class="editorStyleTitle mt-4">Desktop margin:</h4>
        <div class="row align-items-center">
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center"
          >
            <div class="pr-2">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="">
              <input
                type="number"
                v-model="block.style.marginTop"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center"
          >
            <div class="pr-2">
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="">
              <input
                type="number"
                v-model="block.style.marginBottom"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>

        <div class="row align-items-center mt-3">
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center"
          >
            <div class="pr-2">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="">
              <input
                type="number"
                v-model="block.style.marginLeft"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center"
          >
            <div class="pr-2">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                  fill="#B3AFB6"
                />
              </svg>
            </div>
            <div class="">
              <input
                type="number"
                v-model="block.style.marginRight"
                class="form-control-sm w-75 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="" v-else>
        <h4 class="editorStyleTitle mt-4">Mobile margin:</h4>
        <div class="row m-0">
          <div class="row align-items-center">
            <div class="col-4 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    min="0"
                    placeholder="Top"
                    v-model="block.style.mobileMarginTop"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="col-4 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    min="0"
                    placeholder="Bottom"
                    v-model="block.style.mobileMarginBottom"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="form-group col-4 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.mobileMarginLeft"
                    min="0"
                    placeholder="Left"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="form-group col-4 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.mobileMarginRight"
                    min="0"
                    placeholder="Right"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageFileComp from "../../ImageFileComp.vue";

export default {
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
  },
  components: {
    ImageFileComp,
  },
  methods: {
    selectedImage(image) {
    
      console.log("Incoming Image",image)
      if (!image) return;

      const useMobileImage = !!this.block.useMobileImage; 

      if (this.isDesktopView || !useMobileImage) {
        this.$set(this.block, "imageSrc", image);
      } else {
        this.$set(this.block, "mobileSrc", image);
      }
    },
  },
  computed: {
    getImage() {
      if (!this.block.imageSrc || this.block.imageSrc === "") {
        return `https://images.quizell.com/website/default_start_page.png`;
      }

      if (this.isDesktopView) {
        return this.block.imageSrc;
      }

      return this.block.useMobileImage &&
        this.block.mobileSrc &&
        this.block.mobileSrc !== ""
        ? this.block.mobileSrc
        : this.block.imageSrc;
    },
  },
};
</script>
<style scoped>
.image-upload {
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  background-color: rgb(227, 227, 227);
  margin-bottom: 16px;
  overflow: hidden;
}

.figureClass {
  height: 198px;
  line-height: 0;
  margin: 0px;
  display: flex;
  overflow: hidden;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.figureClass >>> img {
  max-height: 100%;
  max-width: 100%;
  pointer-events: none;
  user-select: none;
}

.colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px !important;
  height: 30px !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 2px !important;
  margin-right: 5px;
  border-radius: 4px;
}
.colorInput::-webkit-color-swatch {
  border: 2px solid #cfcece;
  border-radius: 4px;
}
.colorInput::-moz-color-swatch {
  border: 2px solid #cfcece;
  border-radius: 4px;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
